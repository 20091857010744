import React, { useEffect, useState } from "react";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import axiosInstance from "../axiosInstance/axiosInstance";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Import skeleton CSS
import UpdateStudentProfileModel from "../components/UpdateStudentProfileModel";
import AddFeeModel from "../components/AddFeeModel";
import InstallmentsModel from "../components/InstallmentsModel";

const StudentProfile = () => {
  const { id } = useParams();
  const [studentData, setStudentData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [isOpen, setIsOpen] = useState(false);
  const [isFeeModelOpen, setIsFeeModelOpen] = useState(false);
  const [studentFee, setStudentFee] = useState(0);
  const [installments, setInstallments] = useState([]);
  const [remainingFee, setRemainingFee] = useState(0);
  const [viewInstallments, setViewInstallments] = useState(false);

  const fetchData = async (studentId) => {
    try {
      const { data } = await axiosInstance.get(
        `/admission/get?id=${studentId}`
      );

      console.log(data);

      setStudentData(data.students[0]);
      setLoading(false); // Set loading to false once data is fetched
    } catch (error) {
      toast.error("Data not fetched");
      setLoading(false); // Set loading to false on error
    }
  };

  // fetch fee
  const fetchFee = async (studentId) => {
    try {
      const { data } = await axiosInstance.get(`/fee/${studentId}`);

      console.log(data);

      const totalPaidAmount = data.reduce((sum, fee) => {
        console.log(fee);
        return sum + fee.amount;
      }, 0);

      setStudentFee(totalPaidAmount);
      setInstallments(data);

      // Calculate remaining fee
      if (studentData && studentData.courseFee) {
        const remaining = studentData.courseFee - totalPaidAmount;
        setRemainingFee(remaining);
      }
    } catch (error) {
      console.log(error);
      toast.error("Amount not fetched");
    }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  useEffect(() => {
    if (studentData) {
      fetchFee(id);
    }
  }, [id, studentData?.courseFee]);

  const handleBack = () => {
    window.history.back();
  };

  // Convert date
  const isoTimestamp = studentData?.createdAt;
  let normalDate = "Invalid date";

  if (isoTimestamp) {
    try {
      const dateObj = new Date(isoTimestamp);
      if (!isNaN(dateObj.getTime())) {
        normalDate = dateObj.toISOString().split("T")[0];
      }
    } catch (error) {
      console.error("Error parsing date:", error);
    }
  }

  // handle model
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  // fee model
  const openFeeModel = () => {
    setIsFeeModelOpen(true);
  };
  const closeFeeModel = () => {
    setIsFeeModelOpen(false);
  };

  // installments model
  const openInstallmentsModel = () => {
    setViewInstallments(true);
  };

  const closeInstallmentsModel = () => {
    setViewInstallments(false);
  };

  const updateInstallments = (newInstallment) => {
    setInstallments((prevInstallments) => [
      ...prevInstallments,
      newInstallment,
    ]);
    const newTotalPaidAmount = studentFee + newInstallment.amount;
    setStudentFee(newTotalPaidAmount);
    setRemainingFee(studentData.courseFee - newTotalPaidAmount);
  };

  const DataItem = ({ label, value }) => (
    <div>
      <span className="font-semibold">{label}:</span> {value}
    </div>
  );

  return (
    <>
      <div className="flex flex-col w-full h-[100vh] overflow-x-hidden">
        <div className="bg-blue-800 flex items-center justify-center font-bold text-center text-2xl py-4">
          <IoReturnUpBackOutline
            onClick={handleBack}
            className="ml-5 bg-white p-2 text-4xl font-bold rounded-md shadow-md cursor-pointer"
          />
          <h1 className="w-full text-center text-white">Student Information</h1>
        </div>
        <div className="flex p-4 w-full">
          {/* Left Side - Student Profile */}
          <div className="w-1/3 h-fit bg-blue-900 shadow-lg rounded-2xl overflow-hidden flex flex-col mr-4">
            <div className="p-4 flex-grow">
              <div className="flex items-center justify-center">
                <div className="bg-gray-300 w-full h-48 flex items-center justify-center overflow-hidden rounded-lg">
                  {/* Placeholder for Profile Image */}
                  {loading ? (
                    <Skeleton
                      height="100%"
                      width="100%"
                      containerClassName="rounded-lg"
                    />
                  ) : studentData?.profileImage ? (
                    <img
                      src={studentData?.profileImage}
                      alt="profile"
                      className="h-full w-full object-cover rounded-lg"
                    />
                  ) : (
                    <Skeleton
                      height="100%"
                      width="100%"
                      containerClassName="rounded-lg"
                    />
                  )}
                </div>
              </div>
              <div className="mt-4 text-center">
                {loading ? (
                  <Skeleton height={30} width={200} />
                ) : (
                  <h2 className="text-xl font-semibold capitalize text-white">
                    {studentData?.studentName}
                  </h2>
                )}
                {loading ? (
                  <Skeleton height={20} width={100} />
                ) : (
                  <>
                    <p className="text-white mt-1">
                      <span>Admission Date: </span>
                      {normalDate}
                    </p>
                    <div
                      onClick={handleOpen}
                      className="bg-white mt-2 rounded cursor-pointer hover:scale-105 transition-all text-blue-900 font-bold px-3 py-1"
                    >
                      Update
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Right Side - Student Details */}
          <div className="w-full h-auto">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="p-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {loading ? (
                    <>
                      <Skeleton count={4} height={20} />
                      <Skeleton count={4} height={20} />
                    </>
                  ) : (
                    <>
                      <div className="bg-gray-100 p-4 rounded-lg">
                        <div className="bg-blue-200 flex items-center justify-center text-blue-900 font-bold text-center text-2xl py-1 rounded-md">
                          <h1 className="w-fit">Personal Information</h1>
                        </div>
                        <DataItem
                          label="Student ID"
                          value={studentData?.studentId}
                        />
                        <DataItem
                          label="Name"
                          value={studentData?.studentName}
                        />
                        <DataItem label="Gender" value={studentData?.gender} />
                        <DataItem
                          label="Date of Birth"
                          value={studentData?.dob}
                        />
                        <DataItem
                          label="Contact No"
                          value={studentData?.number}
                        />
                        <DataItem label="Email" value={studentData?.email} />
                        <DataItem
                          label="Aadhar No"
                          value={studentData?.aadharNumber}
                        />
                      </div>
                      <div className="bg-gray-100 p-4 rounded-lg">
                        <div className="bg-blue-200 flex items-center justify-center text-blue-900 font-bold text-center text-2xl py-1 rounded-md">
                          <h1 className="w-fit">Family Details</h1>
                        </div>
                        <DataItem
                          label="Father's Name"
                          value={studentData?.fatherName}
                        />
                        <DataItem
                          label="Mother's Name"
                          value={studentData?.motherName}
                        />
                        <DataItem
                          label="Guardian No."
                          value={studentData?.guardianNumber}
                        />
                        <DataItem label="City" value={studentData?.city} />
                        <DataItem label="State" value={studentData?.state} />
                        <DataItem
                          label="Address"
                          value={studentData?.address}
                        />
                      </div>
                      <div className="bg-gray-100 p-4 rounded-lg">
                        <div className="bg-blue-200 flex items-center justify-center text-blue-900 font-bold text-center text-2xl py-1 rounded-md">
                          <h1 className="w-fit">Educational Background</h1>
                        </div>
                        <DataItem label="Degree" value={studentData?.degree} />
                        <DataItem
                          label="College Name"
                          value={studentData?.collegeName}
                        />
                        <DataItem
                          label="Passing Year"
                          value={studentData?.passingYear}
                        />
                        <DataItem label="Grade" value={studentData?.grade} />
                      </div>
                      <div className="bg-gray-100 p-4 rounded-lg">
                        <div className="bg-blue-200 flex items-center justify-center text-blue-900 font-bold text-center text-2xl py-1 rounded-md">
                          <h1 className="w-fit">Course Details</h1>
                        </div>
                        <DataItem
                          label="Course Name"
                          value={studentData?.courseName}
                        />
                        <DataItem
                          label="Duration"
                          value={studentData?.duration}
                        />
                        <DataItem
                          label="Batch Timing"
                          value={studentData?.batchTiming}
                        />
                        <DataItem
                          label="Faculty Name"
                          value={studentData?.facultyName}
                        />
                        <DataItem
                          label="Admission Date"
                          value={studentData?.admissionDate}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="bg-gray-100 p-4 mt-4 rounded-lg">
                  <div className="bg-blue-200 flex items-center justify-center text-blue-900 font-bold text-center text-2xl py-1 rounded-md">
                    <h1 className="w-fit">Student Fee Details</h1>
                  </div>
                  <div className="flex gap-5 mt-4">
                    {studentData?.courseFee > 0 ? (
                      ""
                    ) : (
                      <div
                        onClick={openFeeModel}
                        className="bg-blue-900 rounded cursor-pointer hover:scale-105 w-fit transition-all text-white font-bold px-3 py-1"
                      >
                        Add fee
                      </div>
                    )}

                    {studentFee !== studentData?.courseFee && (
                      <div
                        onClick={openInstallmentsModel}
                        className="bg-blue-900 rounded cursor-pointer hover:scale-105 w-fit transition-all text-white font-bold px-3 py-1"
                      >
                        Pay Installments
                      </div>
                    )}
                    {installments.length > 0 &&
                      studentFee === studentData?.courseFee && (
                        <div className="bg-green-500 px-5 py-2 rounded">
                          <h1 className="text-2xl text-white font-bold">
                            Fee Completed
                          </h1>
                        </div>
                      )}
                  </div>
                  <div className="flex justify-between mb-5">
                    <div className="bg-blue-100 rounded-lg font-bold w-fit p-2 mt-4">
                      <DataItem
                        label="Course fee"
                        value={`₹ ${studentData?.courseFee}/-`}
                      />
                    </div>
                    <div className="bg-green-100 rounded-lg font-bold w-fit p-2 mt-4">
                      <DataItem
                        label="Total Paid Amount"
                        value={`₹ ${studentFee}/-`}
                      />
                    </div>
                    <div className="bg-red-100 rounded-lg font-bold w-fit p-2 mt-4">
                      <DataItem
                        label="Remaining fee"
                        value={`₹ ${remainingFee}/-`}
                      />
                    </div>
                  </div>
                  <div className="relative flex flex-col w-full h-full overflow-auto text-gray-700 bg-white shadow-md rounded-lg">
                    <table className="w-full text-left">
                      <thead>
                        <tr>
                          <th className="p-4 border-b border-slate-200 bg-slate-50">
                            <p className="text-sm font-bold leading-none text-slate-500">
                              S.No.
                            </p>
                          </th>
                          <th className="p-4 border-b border-slate-200 bg-slate-50">
                            <p className="text-sm font-bold leading-none text-slate-500">
                              Amount
                            </p>
                          </th>
                          <th className="p-4 border-b border-slate-200 bg-slate-50">
                            <p className="text-sm font-bold leading-none text-slate-500">
                              Date
                            </p>
                          </th>
                          <th className="p-4 border-b border-slate-200 bg-slate-50">
                            <p className="text-sm font-bold leading-none text-slate-500">
                              Mode
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {installments.map((fee, index) => (
                          <tr
                            key={fee._id}
                            className="hover:bg-slate-50 border-b border-slate-200"
                          >
                            <td className="p-4 py-5">
                              <p className="block font-semibold text-sm text-slate-800">
                                {index + 1}
                              </p>
                            </td>
                            <td className="p-4 py-5">
                              <p className="text-sm text-slate-500">
                                ₹{fee.amount}/-
                              </p>
                            </td>
                            <td className="p-4 py-5">
                              <p className="text-sm text-slate-500">
                                {new Date(fee.date).toLocaleDateString()}
                              </p>
                            </td>
                            <td className="p-4 py-5">
                              <p className="text-sm text-slate-500">
                                {fee.mode}
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpdateStudentProfileModel
        studentData={studentData}
        setStudentData={setStudentData}
        isOpen={isOpen}
        handleClose={handleClose}
      />
      <AddFeeModel
        isOpen={isFeeModelOpen}
        studentData={studentData}
        setStudentData={setStudentData}
        handleClose={closeFeeModel}
      />
      <InstallmentsModel
        isOpen={viewInstallments}
        handleClose={closeInstallmentsModel}
        updateInstallments={updateInstallments}
        studentData={studentData}
      />
    </>
  );
};

export default StudentProfile;
