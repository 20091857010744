export const sidebarRoutes = {
    user: [
        { path: "/", label: "Dashboard" },
        { path: "/job", label: "Job Post" },
        // { path: "/blogs", label: "Blogs" },
    ],
    admin: [
        { path: "/", label: "Dashboard" },
        { path: "/job", label: "Job Post" },
        { path: "/enquiry", label: "Enquiry" },
        { path: "/salary-slip", label: "Salary Slip" },
        // { path: "/blogs", label: "Blogs" },
        {path: "/student-admission", label: "Students Admission"},
    ],
}