import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout/Layout";
import Job from "./pages/Job";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import ProtectedRoute from "./Layout/ProtectedRoute";
import './App.css'
import Enquiry from "./pages/Enquiry";
import Blogs from "./pages/Blogs";
import SalarySlip from "./pages/SalarySlip";
import StudentAdmission from "./pages/StudentAdmission";
import StudentProfile from "./pages/StudentProfile";
import 'react-loading-skeleton/dist/skeleton.css'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
          <Route index element={<Dashboard />} />
          <Route path="/job" element={<Job />} />
          <Route path="/enquiry" element={<Enquiry />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/salary-slip" element={<SalarySlip />} />
          <Route path="/student-admission" element={<StudentAdmission />} />
          <Route path="/student-admission/:id" element={<StudentProfile />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
