import React, { useState } from "react";
import { GiSplitCross } from "react-icons/gi";
import axiosInstance from "../axiosInstance/axiosInstance";
import toast from "react-hot-toast";

const InstallmentsModel = ({ isOpen, handleClose, studentData,updateInstallments  }) => {
    
  const [installmentAmount, setInstallmentAmount] = useState(0);
  const [paymentMode, setPaymentMode] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.post(
        `/fee/add_installment/${studentData._id}`,
        { amount: installmentAmount, mode: paymentMode }
      );

      console.log(data);
      
      updateInstallments(data);
      handleClose();
      toast.success("Student data updated successfully");
      // handleClose();
    } catch (error) {
      console.log(error);
      toast.error("Failed to update student data");
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div>
        {isOpen && (
          <div className="fixed inset-0 px-2 z-10 overflow-hidden flex items-center justify-center">
            <div
              className={`absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity ${
                isOpen ? "opacity-100" : "opacity-0"
              }`}
              style={{ transitionDuration: "300ms" }}
            ></div>
            <div
              className={`bg-white rounded-md shadow-xl w-[400px] overflow-hidden mx-10 z-50 transform ${
                isOpen ? "scale-100" : "scale-75"
              }`}
              style={{
                transitionDuration: "300ms",
                transitionProperty: "transform, opacity",
              }}
            >
              <div className="bg-indigo-500 text-white px-4 py-2 flex justify-between">
                <h2 className="text-lg font-semibold">Add Course Fee</h2>
                <GiSplitCross
                  onClick={handleClose}
                  className="h-10 w-10 p-2 bg-red-500 text-white rounded-md cursor-pointer sm:w-auto"
                />
              </div>
              <form className="p-4" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="course_fee"
                    className="block text-gray-700 text-xl"
                  >
                    Amount
                  </label>
                  <input
                    type="number"
                    name="amount"
                    id="amount"
                    onChange={(e)=>setInstallmentAmount(e.target.value)}
                    placeholder="Amount"
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div>
                <label
                    htmlFor="mode"
                    className="block text-gray-700 text-xl"
                  >
                    Payment Mode
                  </label>
                  <input
                    type="text"
                    name="mode"
                    id="mode"
                    value={paymentMode}
                    onChange={(e) => setPaymentMode(e.target.value)}
                    placeholder="Mode"
                    className="w-full p-2 border border-gray-300 rounded-md"
                    list="paymentMode"
                  />
                  <datalist id="paymentMode">
                    <option value="Cash">Cash</option>
                    <option value="Bank Transfer">Bank Transfer</option>
                    <option value="UPI">UPI</option>
                    <option value="Card">Card</option>
                  </datalist>
                </div>
                <div className="mt-4 flex justify-end">
                  <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InstallmentsModel;
